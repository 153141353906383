import logo from './logo.svg';
import './App.css';
import { Amplify, Auth, API } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';

// Configure AWS
import awsConfig from './aws-exports';

console.log("Starting")
if (process.env.NODE_ENV === "development") {
    // Cookies don't work in development
    console.log("Disabling cookies in development")
    delete awsConfig.Auth.cookieStorage
}
Amplify.configure(awsConfig);

// You can get the current config object
const currentConfig = Auth.configure();
console.log(`Current auth config is ${currentConfig}`)

async function getUserInfo(user) {
  const apiName = 'WarsAPI';
  const path = `/users/${user.username}`;
  const requestParams = { // OPTIONAL
    headers: {}, // OPTIONAL
    statusCode: 200,
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
      name: 'param',
    },
  };

  const response = await API.get(apiName, path, requestParams)
  console.log(`user info is ${response}`)
  return response.data
}

function App({ signOut, user }) {
  const response = getUserInfo(user)
  // response is a promise so we cannot use it directly

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Hello {user.username}</h1>
        <button onClick={signOut}>Sign out</button>
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default withAuthenticator(App);
